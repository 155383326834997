<template>
	<div class="bg">
		<div class="head">
			<div class="container">
				<div class="container_box">
					<div class="nav_box">
						<div class="right">
							<ul class="nav">
								<li><a href="https://www.zhilanbofeng.cn/index.html">首页</a></li>
								<li><a href="https://www.zhilanbofeng.cn/product.html">服务器产品</a></li>
								<li class="dropul">
									<a href="###">解决方案</a>
									<div class="drop">
										<ul>
											<li><a href="https://www.zhilanbofeng.cn/ecology.html"
													style="line-height: 40px;">全产业生态</a></li>
											<li><a href="https://www.zhilanbofeng.cn/storage.html"
													style="line-height: 40px;">下一代云存储</a></li>
										</ul>
									</div>
								</li>
								<li><a href="https://www.zhilanbofeng.cn/about.html">关于我们</a></li>
							</ul>
							<div class="operation">
								<div>
									<img src="../../assets/img/ic_denglu.png" alt="智岚博丰">
								</div>
								<div class="login"><a href="./login">登录</a></div>
								<div class="register"><a href="./register">注册</a></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="operation-box">
			<div class="outside-box">
				<div class="options">
					<a class="login" href="./login">密码登录</a>
					<a class="register" href="./register">用户注册</a>
				</div>
				<div class="form-box">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
						<el-form-item prop="Passport" style="text-align:center;">
							<el-input placeholder="请输入手机号" v-model="ruleForm.Passport" key="Passport"></el-input>
						</el-form-item>
						<el-form-item prop="Password" style="text-align:center;">
							<el-input placeholder="请输入密码" v-model="ruleForm.Password" show-password key="Password">
							</el-input>
						</el-form-item>
						<el-form-item prop="Password2" style="text-align:center;">
							<el-input placeholder="重复密码" v-model="ruleForm.Password2" show-password
								key="Password2"></el-input>
						</el-form-item>
						<el-form-item prop="Recommended" style="text-align:center;">
							<el-input placeholder="请输入推荐码" v-model="ruleForm.Recommended" key="Recommended"></el-input>
						</el-form-item>
						<div class="code">
							<el-form-item prop="verifyCode" style="text-align:center;display: inline-block;margin-bottom: 0;">
								<el-input placeholder="请输入验证码" v-model="ruleForm.verifyCode" key="verifyCode"></el-input>
							</el-form-item>
							<img style="width: 120px; height: 40px;display: inline-block;margin-left: 10px;" :src="ruleForm.verifyCodeImg" @click="handleRefresh()">
						</div>
						<el-form-item prop="name" style="text-align:center;">
							<el-checkbox-group v-model="ruleForm.type"
								style="text-align:left;font-size:18px;color:#333333">
								<el-checkbox label="同意会员注册协议" name="type"></el-checkbox>
							</el-checkbox-group>
						</el-form-item>
						<el-form-item>
							<el-button @click="resetForm('ruleForm')">取消</el-button>
							<el-button type="primary" @click="handleRegister">注册</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Admin
	} from "@/api/index";
	export default {
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入密码'));
				} else {
					if (this.ruleForm.checkpass !== '') {
						this.$refs.ruleForm.validateField('checkpass');
					}
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.ruleForm.password) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			return {
				ruleForm: {
					Passport:'',
					Password: '',
					Password2:'',
					Recommended:'',
					verifyCode:'',
					verifyKey:'',
					verifyCodeImg:'',
					type:'',
				},
				rules: {
					Passport: [{
							required: true,
							message: '请输入手机',
							trigger: 'blur'
						},
						{
							pattern: /^((13[0-9])|(14[5-9])|(15([0-3]|[5-9]))|(16[6-7])|(17[1-8])|(18[0-9])|(19[1|3])|(19[5|6])|(19[8|9]))\d{8}$/,
							message: '手机号格式不正确',
							trigger: 'blur'
						},
					],
					Password: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						{
							validator: validatePass,
							trigger: 'blur'
						}
					],
					Password2: [{
							required: true,
							message: '请再次输入密码',
							trigger: 'blur'
						},
						{
							validator: validatePass,
							trigger: 'blur'
						}
					],
					Recommended: [{
							required: true,
							message: '请输入推荐码',
							trigger: 'blur'
						},
					],
					verifyCode: [{
							required: true,
							message: '请输入验证码',
							trigger: 'blur'
						},
					],
					type: [{
						type: 'array',
						required: true,
						message: '请同意本站用户协议',
						trigger: 'change'
					}],
				}
			};
		},
		created() {
			this.handleCaptcha()
		},
		methods: {
			//获取验证码
			async handleCaptcha(values) {
				let { status, data } = await Admin.verifyCode();
				if (status) {
					if (data.code === 0) {
						this.ruleForm.verifyCodeImg = data.data.img
						this.ruleForm.verifyKey = data.data.key
					} 
				} else {
					this.$message.error("data.message")
				}
			},
			//点击图片重新获取验证码
			handleRefresh() {
				this.handleCaptcha();
			},
			handleRegister() {
				if (this.ruleForm.type.length == 0) {
					this.$message.error("请先勾选注册协议");
					return
				}
				// 获取组件对象，校验整个表单
				this.$refs.ruleForm.validate(async (valid) => {
					if (valid) {
						let {
							status,
							data
						} = await Admin.register(this.ruleForm);
						if (status) {
							if (data.code != 0) {
								this.$message.error(data.message);
								return
							} else {
								this.$message.success('注册成功');
								this.$router.replace('/login');
							}
						} else {
							this.$message.error(data);
						}
					}
				});
			}
		}
	}
</script>

<style lang="less" scoped>
	html {
		width: 100%;
		height: 100%;
	}

	body {
		width: 100%;
		height: 100%;
	}

	.bg {
		width: 100%;
		height: 100%;
		background: url(../../assets/img/ic_denglubeijing.jpg) no-repeat center center;
		background-size: cover;
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	.head {
		height: 89px;
		display: none;
	}

	.head .container {
		width: 90%;
		margin: 0 auto;
	}

	.head .container_box {
		width: 100%;
		display: flex;
		position: relative;
		justify-content: space-between;
	}

	.nav_box {
		width: 100%;
		display: flex;
		position: relative;
	}

	.head .container .logo {
		padding: 36px 0 19px 0;
		position: absolute;
		left: 0;
	}

	.head .container .right {
		display: flex;
		position: absolute;
		right: 0;
	}

	.head .container .nav {
		list-style-type: none;
		display: flex;
		padding-left: 0;
		height: 89px;
	}

	.head .container .nav li {
		margin: 0 50px 0 0;
	}

	.head .container .nav li:first-child {
		margin: 0 50px 0 0;
	}

	.head .container .nav li a {
		font-size: 16px;
		color: #fff;
		line-height: 89px;
	}

	.head .container .operation {
		display: flex;
		align-items: center;
		right: 0;
		color: #fff;
		padding: 30px 22px 29px 0;
		position: relative;
	}

	.head .container .operation>div {
		height: 30px;
		vertical-align: middle;
	}

	.head .container .operation a {
		font-size: 16px;
		color: #fff;
		line-height: 30px;
	}

	.head .container .operation a:hover {
		color: #0088ff;
	}

	.head .container .operation>div {
		display: flex;
		align-items: center;
	}

	.head .container .operation>div>img {
		height: 24px;
		width: 21px;
		margin-right: 9px;
	}

	.head .container .operation .login {
		margin-right: 15px;
	}

	.head .container .operation .register {
		margin-left: 15px;
		cursor: pointer;
	}

	.head .container .operation .login::before {
		content: "";
		width: 2px;
		height: 16px;
		background-color: #ccc;
		position: absolute;
		top: 38px;
		right: 68px;
	}

	.drop ul {
		background-color: #2695f6;
		width: 120px;
		text-align: center;
		position: absolute;
		top: 64px;
		right: 283px;
		border-radius: 6px;
		z-index: 9;
		display: none;
	}

	.drop ul li {
		line-height: 20px;
		width: 100%;
	}

	/*一个很重要的三角形*/
	.drop li:first-child:before {
		content: " ";
		font-size: 0;
		line-height: 0;
		margin: 0 auto;
		display: block;
		background-color: #2695f6;
		width: 10px;
		height: 10px;
		transform: rotate(45deg);
		transform: rotate(45deg);
		position: relative;
		top: -5px;
		z-index: -1;
	}

	.drop li {
		background-color: #2695f6;
		z-index: 99;
	}

	.drop li a {
		color: #fff;
		line-height: 46px;
		font-size: 14px;
		display: block;
		position: relative;
		z-index: 2;
	}

	.drop li:first-child a {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		margin-top: -10px;
	}

	.drop li:last-child a {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		border-bottom: none;
	}

	.drop li:hover {
		background-color: #0067de;
		z-index: 999;
	}

	.dropul:hover .drop ul {
		display: block;
	}

	.operation-box {
		background-color: #ffffff;
		width: 581px;
		height: 630px;
		border-radius: 12px;
		position: absolute;
		right: 178px;
		top: 50%;
		transform: translateY(-50%);
		.code {
			display: flex;
			align-items: center;
		}
		.outside-box {
			position: relative;

			.top-zlbf {
				height: 72px;
				width: 256px;
				background-color: #00C2FF;
				color: #ffffff;
				font-size: 30px;
				line-height: 72px;
				text-align: center;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				border-radius: 6px;
				top: -125px;
				z-index: 999;
			}

			.options {
				display: flex;
				justify-content: space-between;
				margin: 87px 111px 70px;

				.login {
					color: #999999;
					font-size: 20px;
					border-bottom: 0;
				}

				.register {
					color: #2695F6;
					font-size: 20px;
					border-bottom: 1px solid #2695F6;
					cursor: pointer;
				}
			}

			.form-box {
				font-size: 18px;
				width: 70%;
				margin: 0 auto;

				.el-input__inner {
					border: 0;
					border-bottom: 1px solid #DCDFE6;
					width: 100%;
					outline: 0;
					padding: 0;
				}
			}
		}
	}

	.el-input {
		font-size: 18px;
	}

	.el-form-item__content {
		text-align: center;
	}
</style>
